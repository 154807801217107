import * as React from 'react'
import styled from 'styled-components'

import { mediaQueries, device } from './device'

const HeaderStyled = styled.div`
  background-color: black;
  color: white;  
`
const HeaderInnerStyled = styled.div`
  margin: auto;
  font-family: arial;
  font-weight: 150;
  font-size: 1.2rem;  
  background-color: black;
  display: flex; 
  justify-content: space-between;
  
  ${mediaQueries} 
  
  @media ${device.mobile} { 
    font-size: .7rem;    
  }
`
const LinkContainerStyled = styled.div`
  display: flex;
  flex: 1 1 0px;
`
const LinkStyled = styled.div`
  border-bottom-color: ${true ? 'black' : '#f7a046' };  
  border-bottom-width: 4px;
  border-bottom-style: solid;

  a {
    display: block;
    padding: 1.2rem;  
    color: white;
    text-decoration: none;    
  }

  a:hover {
    color: #f7a046;  
  }
  &:hover {
    border-bottom-color: #f7a046;  
  }

  @media ${device.mobile} { 
    a {      
      padding: .2rem;        
    }    
  }
`
const HomeLinkStyled = styled(LinkStyled)`
  a {
    color: #DDDDDD;
  }
  font-weight: 700;
`
const MiddleLinkContainerStyled = styled(LinkContainerStyled)`
  display: flex;
  justify-content: center;
`
const SocialLinkContainerStyled = styled(LinkContainerStyled)`
  display: flex;
  justify-content: flex-end;
`
const SocialLinkStyled = styled(LinkStyled)`
  svg {
    fill: white;
  }    
`

export const Header = () => {
  return (
    <HeaderStyled>
      <HeaderInnerStyled>
        
        <LinkContainerStyled>
          <HomeLinkStyled><a href='https://www.findrobbrodie.com'>FindRobBrodie.com</a></HomeLinkStyled>          
        </LinkContainerStyled>        

        <MiddleLinkContainerStyled>
          <LinkStyled><a href='https://www.findrobbrodie.com'>Blog</a></LinkStyled>
          <LinkStyled><a href='https://www.findrobbrodie.com'>Projects</a></LinkStyled>
        </MiddleLinkContainerStyled>

        <SocialLinkContainerStyled>
          <SocialLinkStyled>
            <a href='https://www.twitter.com/findrobbrodie'>
              <img 
                src="data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E" 
                title='Twitter'
                alt='Twitter'
                width='20px'
                height='20px' 
              />
            </a>
          </SocialLinkStyled>
          <SocialLinkStyled>
            <a href='https://www.github.com/findrobbrodie'>
              <svg height="20" viewBox="0 0 16 16" version="1.1" width="20" aria-hidden="true"><title>Github</title><path fillRule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg>
            </a>
          </SocialLinkStyled>
        </SocialLinkContainerStyled>              

      </HeaderInnerStyled>
    </HeaderStyled>    
  )
}
