export const device = {
  mobile: `(max-width: 767px)`,    
  tablet: `(min-width: 768px)`,  
  smallMonitor: `(min-width: 992px)`,
  largeMonitor: `(min-width: 1200px)`
}

export const mediaQueries = `
  @media ${device.tablet} { 
    max-width: 723px;
  }

  @media ${device.smallMonitor} { 
    max-width: 933px;
  }

  @media ${device.largeMonitor} {
    max-width: 1127px;
  }
`