import * as React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'

interface Props {
  children: any
}

export const AppLayout = ({ children }: Props) => {
  return (
    <div>

      <Header />

      <div style={{minHeight: '400px'}}>
        {children}
      </div>

      <Footer />

    </div>
  )
}
