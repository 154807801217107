import * as React from 'react'
import styled from 'styled-components'

import { mediaQueries, device } from './device'

const FooterStyled = styled.div`
  min-height: 300px;
  background-color: black;
  color: white;
  z-index: 10000;  
`
const FooterInnerStyled = styled.div`
  margin: auto;
  padding-top: 1rem;
  font-family: arial;
  font-weight: 150;
  font-size: 1.2rem;
  
  background-color: black;
  display: flex; 
  justify-content: space-between;

  font-family: arial;
  font-weight: 150;
  font-size: .9rem;
  color: #CCCCCC;

  a {
    display: block;
    padding: .6rem;  
    color: white;
    text-decoration: none;    
  }

  ${mediaQueries}  

  @media ${device.mobile} { 
    display: block;
    
  }
`
const H2Styled = styled.h2`
  a {
    color: #DDDDDD;
  }  
  font-family: arial;
  font-weight: 700;
  font-size: 1.2rem;

  a:hover {
    color: #f7a046;  
  }
  &:hover {
    border-bottom-color: #f7a046;  
  }
`
const LinkStyled = styled.div`  
  font-size: .9rem;
  font-weight: 300;
  a:hover {
    color: #f7a046;  
  }
  &:hover {
    border-bottom-color: #f7a046;  
  }
`

export const Footer = () => {
  return (
    <FooterStyled>
      <FooterInnerStyled>
        <div>
          <H2Styled><a href='https://www.findrobbrodie.com'>Blog</a></H2Styled>

          <LinkStyled><a href='https://www.findrobbrodie.com'>About Me</a></LinkStyled>
          <LinkStyled><a href='https://www.findrobbrodie.com'>Contact</a></LinkStyled>
        </div>      
        <div>
          <H2Styled><a href='https://www.findrobbrodie.com'>Projects</a></H2Styled>

          <LinkStyled><a href='https://brodeo-builder.findrobbrodie.com'>Brodeo Builder</a></LinkStyled>
          <LinkStyled><a href='https://brodie-weekly-planner.findrobbrodie.com'>Brodie Weekly Planner</a></LinkStyled>
        </div>
        <div>
          <H2Styled><a href='https://www.findrobbrodie.com'>Videos</a></H2Styled>
        </div>
        <div>
          <H2Styled><a href='https://www.findrobbrodie.com'>Social</a></H2Styled>

          <LinkStyled><a href='https://www.twitter.com/findrobbrodie'>Twitter</a></LinkStyled>
          <LinkStyled><a href='https://www.github.com/findrobbrodie'>Github</a></LinkStyled>
        </div>
      </FooterInnerStyled>
    </FooterStyled>
  )
}
